import React from 'react'
import './Button.scss'

const Arrow = ({color, rotate}) => {
  return (
    <svg
      height="20"
      width="45"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current transform text-${color} ${rotate && 'rotate-180'}`}
      strokeWidth="2"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1.5 10H37" strokeLinecap="square"></path>
        <path d="M30.5 1.515L38.985 10 30.5 18.485"></path>
      </g>
    </svg>
  )
}
Arrow.defaultProps = {
  color: 'white',
  rotate: false
}

const DownloadIcon = () => {
  return (
    <svg width="24px" height="21px" viewBox="0 0 24 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Download Icon</title>
      <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1000.000000, -1326.000000)" strokeWidth="2">
              <g id="Group-10" transform="translate(868.000000, 1314.000000)">
                  <g id="Group-6" stroke="#2F2F2F">
                      <rect id="Rectangle" x="1" y="1" width="179" height="46" rx="23"></rect>
                  </g>
                  <g transform="translate(133.000000, 13.000000)">
                      <g id="Group-8" transform="translate(0.000000, 11.340000)" stroke="#010101" strokeLinecap="square">
                          <line x1="0.34171875" y1="6.885" x2="21.5282813" y2="6.885" id="Line"></line>
                          <line x1="0.405" y1="0.190588235" x2="0.405" y2="6.28941176" id="Line-20-Copy"></line>
                          <line x1="21.465" y1="0.190588235" x2="21.465" y2="6.28941176" id="Line-20-Copy-2"></line>
                      </g>
                      <g transform="translate(4.860000, 0.000000)" stroke="#000000">
                          <line x1="6.156" y1="0.324" x2="6.156" y2="13.284" id="Line-20" strokeLinecap="square"></line>
                          <polyline id="Rectangle" transform="translate(6.156000, 8.100000) rotate(-225.000000) translate(-6.156000, -8.100000) " points="1.944 3.888 10.368 3.888 10.368 12.312"></polyline>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}



export const ArrowOnlyButton = ({
  to,
  target,
  direction,
  color,
  onClick
}) => {
  return (
      <a
        className={`arrow-only-btn direction-${direction} border-${color} text-${color}`}
        href={to}
        target={target}
        onClick={onClick}
      >
        <Arrow color={color} />
      </a>
  )
}
ArrowOnlyButton.defaultProps = {
  direction: 'right',
  color: 'darkgray'
}

export const StyledLink = ({
  to,
  target,
  arrow,
  children,
  color,
  noOutline,
  download,
  btnEl
}) => {


  return (
    <>
      <a
        className={`
          btn
          direction-${arrow}
          border-${color}
          text-${color}
          ${arrow ? 'btn-arrow' : ''}
          ${download ? 'btn-download' : ''}
          ${noOutline ? 'no-outline' : ''}`
      }
        href={to}
        target={target}
      >
        {arrow === 'left' && (
          <Arrow color={color} />
        )}
        <span>{ children }</span>
        {arrow === 'right' && (
          <Arrow color={color} />
        )}
        {download && (
          <DownloadIcon color={color} />
        )}
      </a>
    </>
  )
}

StyledLink.defaultProps = {
  arrow: false,
  color: 'darkgray',
  solid: false,
  btnEl: false,
}
