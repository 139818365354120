import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { ReactComponent as Logo } from '../../images/egenesis-logo.svg'
import './SiteFooter.scss';


const MissionBlock = ({missionStatement}) => {
  return (
    <>
      <h3 className="opacity-50">OUR MISSION</h3>
      <p className="text-sm italic" dangerouslySetInnerHTML={{__html: missionStatement}}/>
    </>
  )
}

const ContactBlock = ({headquartersAddress}) => {
  return (
    <>
      <div className='col-span-2'><h3>CONTACT US</h3></div>
      <div className="footer-address mb-6 md:mb-0" dangerouslySetInnerHTML={{__html: headquartersAddress}} />
    </>
  )
}

const SocialMedia = ({socialMedia}) => {
  return (
    <ul className="flex">
      {socialMedia.map( (social, i) => (
        <li key={i} className="mr-4">
          <a className="footer-icon" href={social.url} target="_blank"><img src={social.icon.sourceUrl} alt={social.icon.title} /></a>
        </li>
      ))}
    </ul>
  )
}

const QuickLinks = ({items}) => {
  return (
    <>
      <h3 className="opacity-50">QUICK LINKS</h3>
      <ul>
        {items.map( (link, i) => (
          <li key={i}>
            <Link to={link.path} className="uppercase text-sm">{link.label}</Link>
          </li>
        ))}
      </ul>
    </>
  )
}

export default function SiteFooter() {
  const data = useStaticQuery(graphql`
    query globalQuery {
      footerData: allWp {
        nodes {
          themeGlobalOptions {
            globalOptions {
              contactEmail
              fieldGroupName
              headquartersAddress
              missionStatement
              socialMedia {
                fieldGroupName
                icon {
                  sourceUrl
                  title
                }
                url
              }
            }
          }
        }
      }
      footerNav: allWpMenuItem(filter: { locations: { eq: GATSBY_FOOTER_MENU } }) {
        nodes {
          label
          url
          id
          path
        }
      }
    }
  `)

  const {
    contactEmail,
    headquartersAddress,
    missionStatement,
    socialMedia
  } = data.footerData.nodes[0].themeGlobalOptions.globalOptions
  const footerNavItems = data.footerNav.nodes
  const currentYear = new Date()


  return (
    <footer className="bg-navy text-white site-footer py-6">
      <div className="container">
        <div className="row mb-4">
          <div className="logo-wrap">
            <Logo />
          </div>
        </div>

        <div className="md:grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="md:grid grid-cols-3 gap-8 col-span-3">
            <div className="mission hidden md:block col-span-1">
              <MissionBlock missionStatement={missionStatement} />
            </div>
            
            <div className="col-span-2">
              <div className="md:grid grid-cols-1 md:grid-cols-2 gap-x-8">
                <ContactBlock
                  headquartersAddress={headquartersAddress}
                />
              </div>
            </div>

            <div className="md:grid gap-8 col-span-3 grid-cols-3">
              <div className="hidden md:block" style={{fontSize: '12px'}}>© {currentYear.getFullYear()} - eGenesis</div>
              <div className="mb-6 md:mb-0">
                <a href={`mailto:${contactEmail}`} className="text-sm">{contactEmail}</a>
              </div>
              <div className="mb-12 md:mb-0">
                <SocialMedia socialMedia={socialMedia} />
              </div>
            </div>
          </div>
          <div>
            <QuickLinks items={footerNavItems} />
          </div>
          <div className="block mt-6 md:hidden" style={{fontSize: '12px'}}>© {currentYear.getFullYear()} - eGenesis</div>
        </div>

      </div>
    </footer>
  )
}
