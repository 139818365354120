import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { flatListToHierarchical } from '../../../utils'

import './SubNav.scss'


const SubNav = () => {
  const data = useStaticQuery(graphql`
    query subNavQuery {
      subNav: allWpMenuItem(filter: { locations: { eq: SUB_NAVIGATION } }) {
        nodes {
          label
          url
          id
          path
          parentId
        }
      }
      allWp {
        nodes {
          generalSettings {
            url
          }
        }
      }
    }
  `)
  const hierarchicalList = flatListToHierarchical(data.subNav.nodes)

  return (
    <ul className="flex mb-3">
      {hierarchicalList && hierarchicalList.map( (page) => (
        <li key={page.id} className={`relative px-4 last:pr-0 ${page.children.length > 0 && 'hoverable'}`}>
          <Link to={page.path} className={`subnav-link text-white transition-all text-sm font-light`}>
            {page.label}
        </Link>
        {page.children.length > 0 &&
          <ul
            className="
              bg-navy
              absolute
              overflow-hidden
              dropdown
              h-0
              z-50
            "
          >
            {page.children.map( (subPage) => (
              <li
                key={subPage.id}
                className="first:border-t border-white pt-3 leading-tight"
              >
                <Link
                  to={subPage.path}
                  className="
                    subnav-link
                    text-white
                    px-2
                    text-sm
                    font-thin
                  ">{subPage.label}
                </Link>
              </li>
            ))}
          </ul>
        }
      </li>
      ))}
    </ul>
  )
}

export default SubNav
