import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './Burger.scss'

const BurgerBar = (props) => <div className={`burger-bar h-0.5 bg-white w-full absolute ${props.extraClasses}`}></div>

const Burger = (props) => {
  const [clicked, setClicked] = useState(false)
  const openClass = props.isOpen ? 'open': ''
  const clickClass = clicked ? 'clicked' : ''

  return (
    <div className={`burger-container relative cursor-pointer ${openClass} ${clickClass}` }
      role="button"
      tabIndex="0"
      onClick={() => {
        props.handleClick()
        setClicked(true)
      }}
      onKeyDown={() => {
        props.handleClick()
        setClicked(true)
      }}
    >
      <BurgerBar extraClasses="top" />
      <BurgerBar extraClasses="middle" />
      <BurgerBar extraClasses='bottom' />
    </div>
  )
}

Burger.propTypes = {
  isOpen: PropTypes.bool
}

export default Burger
