import { useState, useRef, useEffect, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
/****
 * remove full path of url and make it relative
 */
export const createLocalLink = url => {
  if (`#` === url) {
        return null
    }
    return url ? url.replace(process.env.WP_URL, '') : url
}

// height determination
export function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [])
  return [{ ref }, bounds]
}


// get window size
export const useWindowSize = () => {
  const [width, setWidth] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [])
  return width
}

/**
 *
 * @param {*} data
 * @param {*} param1
 *
 * function to handle nesting nav items
 */
export const flatListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = []
  const childrenOf = {}
  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}

/* const padding = { padding: `${topSpacing}px 0 ${bottomSpacing}px 0` }
  const bgColor = tintedBackground ? 'bg-lightblue' : 'bg-white'
  */

export const padding = (top, bottom) => {
  const nullConvert = (i) => i === null ? 0 : i

  return { padding: `${nullConvert(top)}px 0 ${nullConvert(bottom)}px 0` }
}

export const bg = (isTinted) => isTinted ? 'bg-lightblue' : 'bg-white'
