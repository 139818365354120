import React from 'react'
import SiteHeader from '../components/SiteHeader/SiteHeader'
import SiteFooter from '../components/SiteFooter/SiteFooter'
import { Helmet } from 'react-helmet'
import favicon from '../images/map-marker.svg'
import { useStaticQuery, graphql } from 'gatsby';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';

import "./Layout.scss"

export default function Layout({children, isHome, title}) {

  const pageTitle = () =>  isHome ? 'eGenesis' : `${title} - eGenesis`
  const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `);


  return (
    <SEOContext.Provider value={{ global: seo }}>
      <div className="site-wrapper">

        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle()}</title>
          
          <link rel="icon" type="image/png" href={favicon} />
        </Helmet>

        <SiteHeader isHome={isHome}/>
        <main>
          {children}
        </main>
        <SiteFooter />
      </div>
    </SEOContext.Provider>
  )
}
