import React, { useState, useRef } from 'react'
import { motion } from "framer-motion";
import { flatListToHierarchical } from '../../../utils'
import { useStaticQuery, graphql, Link, PageRenderer } from 'gatsby'
import Burger from './Burger'

import './MobileNav.scss'

const Dropdown = ({items}) => {
  const variants = {
    start: {
      height: 0,
    },
    end: {
      height: 'auto'
    }
  }

  return (
    <motion.ul className="mt-4 overflow-hidden"
      initial="start"
      animate="end"
      variants={variants}
      transition={{ duration: 0.15 }}
    >
      {items.map( item => (
        <li key={item.id}>
          <Link
            className="text-white px-4 py-2 block"
            to={item.path}>{item.label}</Link>
        </li>
      ))}
    </motion.ul>
  )
}

const NavItem = ({page}) => {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <li className="py-4">
        {page.path === '#' ? (
          <a className="
            text-white
            hover:text-red
            uppercase
            font-medium"
            onClick={handleClick}
          >
            {page.label}
          </a>
        ):(
          <Link to={page.path}
            className="
              text-white
              hover:text-red
              uppercase
              font-medium
            "
          >
            {page.label}
          </Link>
        )}

        {page.children && page.children.length > 0 &&
        <>
          {expanded && (
            <Dropdown items={page.children} />
          )}
        </>
        }
      </li>
    </>
  )
}

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query mobileNavQuery {
      mainNav: allWpMenuItem(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
        nodes {
          label
          url
          id
          path
        }
      }
      subNav: allWpMenuItem(filter: { locations: { eq: SUB_NAVIGATION } }) {
        nodes {
          label
          url
          id
          path
          parentId
        }
      }
      allWp {
        nodes {
          generalSettings {
            url
          }
        }
      }
    }
  `)

  const primary = flatListToHierarchical(data.mainNav.nodes)
  const secondary = flatListToHierarchical(data.subNav.nodes)
  const navItems = primary.concat(secondary)
  const ref = useRef(null)
  // console.log('nodes', data.mainNav.nodes)
  // console.log('filtered', flatListToHierarchical(data.mainNav.nodes))

  const navigation = {
    open: {
      height: '100%',
    },
    closed: {
      height: 0,
    }
  };

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Burger isOpen={isOpen} handleClick={handleClick} />
      <motion.div
        className="mobile-nav overflow-scroll"
        initial='closed'
        animate={isOpen ? "open" : "closed"}
        variants={navigation}
        transition={{ duration: 0.5 }}
      >
        <div className="container py-12">
          <ul>
            {navItems && navItems.map((page) => (
              <NavItem key={page.id} page={page} />
            ))}
          </ul>
        </div>
      </motion.div>
    </>
  )
}

export default MobileNav
