import React from 'react'
import { useStaticQuery, graphql, Link, PageRenderer } from 'gatsby'

import { flatListToHierarchical } from '../../../utils'

import './MainNav.scss'

const DropDown = ({pages}) => {
  return (
    <ul className="main-dropdown">
      {pages.map( page => (
        <li key={page.id}>
          <Link to={page.path}>
            {page.label}
          </Link>
          {page.children.length > 0 && (
            <DropDown pages={page.children} />
          )}
        </li>
      ))}

    </ul>
  )
}

const MainNav = () => {


  const data = useStaticQuery(graphql`
    query NavQuery {
      mainNav: allWpMenuItem(
        sort: {order: ASC, fields: order}
        filter: { locations: { eq: GATSBY_HEADER_MENU } }
      ) {
        nodes {
          label
          url
          id
          path
          parentId
        }
      }
      allWp {
        nodes {
          generalSettings {
            url
          }
        }
      }
    }
  `)


  const navItems = flatListToHierarchical(data.mainNav.nodes)

  return (
    <ul className="primary-nav flex w-full justify-end">
      {navItems && navItems.map( (page) => (
        !page.parentId && (
          <li key={page.id} className="last:mr-0 block relative">
            <Link to={page.path}
              className="
                block
                pb-5
                px-4
                nav-link
                text-sm
                text-white
                font-medium
                uppercase
                transition-all
                ease-in
                duration-200
                border-b-4
                border-opacity-0
                border-red
                hover:border-opacity-100
              "
            >{page.label}</Link>
            {page.children.length > 0 && (
              <DropDown pages={page.children} />
            )}
          </li>
        )
      ))}
    </ul>
  )
}

export default MainNav
